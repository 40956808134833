body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lato', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif; */
  font-size: 18px;
  line-height: 28px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tt-tryit-background {
  color: white;
  background-color: #5aaf5e;
}

.tt-tryit-header {
  color: white;
  background-color: #5aaf5e;
  font-size: 35px;
  line-height: 44px;
  font-weight: 500;
  padding-top: 80px;
  padding-bottom: 12px;
}

.tt-tryit-description{
  font-family: Lato, sans-serif;
  color: #000;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  margin: 0;
}

.tt-tryit-description2{
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
  margin: 0;
}

@media (min-width: 768px) {
.tt-tryit-form{
  font-family: 'Poppins', sans-serif;
  margin-left: 20%;
  margin-right: 20%;
}

.tt-tryit-form2{
  margin-left: 20%;
  margin-right: 20%;
}
}

.form-label{
    font-family: Poppins, sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
}

.form-input{
  font-family: Poppins, sans-serif;
  padding: 8px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  width: 100%;
}

.form-button-res, .form-button-res:focus {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  border-radius: 20px;
  background-color: #fff;
  color: #4d86ff;
  display: inline-block;
  padding: 9px 15px;
  text-align:center;
  margin-bottom:30px;
  border: 0;
  outline: 0;
  width: 60%;
  font-weight: bold;
}

.tt-results {
  color: black;
  background: white;
  padding: 12px;
}

.close-button{
  border: 0;
  outline: 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.3);
}

.res-head{
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  line-height: 50px;
}

.res-text-1{
  font-size: 32px;
  line-height: 50px;
}

.res-text-2{
  font-family: Poppins, sans-serif;
  color: #5aaf5e;
  font-size: 50px;
  line-height: 50px;
  font-weight: 400;
  line-height: 28px;
}

.res-text-3 {
  font-family: Poppins, sans-serif;
  color: #4d86ff;
  line-height: 15px;
  font-weight: 300;
  line-height: 28px;
}

.res-text-4 {
  font-family: Poppins, sans-serif;
  color: black;
  line-height: 15px;
  font-weight: 300;
  line-height: 28px;
}

.tt-physicians-background {
  color: white;
  background-color: #4d86ff;
}

.tt-physicians-header {
  color: white;
  background-color: #4d86ff;
  font-size: 35px;
  line-height: 44px;
  font-weight: 500;
  padding-top: 80px;
}

.tt-physicians-description{
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 18px;
  line-height: 28px;
  font-weight: 300;
}

@media (min-width: 768px) {
.tt-physicians-form{
  font-family: 'Poppins', sans-serif;
  margin-left: 30%;
  margin-right: 30%;
}

.tt-physicians-form2{
  margin-left: 35%;
  margin-right: 35%;
}
}
.form-label{
    font-family: Poppins, sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
}

.form-input{
  font-family: Poppins, sans-serif;
  padding: 8px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  width: 100%;
}

.form-button {
  font-size: 16px;
  border-radius: 20px;
  margin-top: 32px;
  border: none;
  padding: 12px 24px;
  font-weight: normal;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: white;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.form-button:hover {
  background-color: #d9d9db;
  color: #3779CA;
}

.found-physicians {
  color: black;
  background: white;
  padding: 12px;
}

.close-button{
  border: 0;
  outline: 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.3);
}

.doc1{
  color: rgb(90, 136, 247);
  font-family: Poppins, sans-serif;
  font-size: 22px;
}

.doc2{
  font-family: Poppins, sans-serif;
  font-size: 16px;
}

.doc3{
  color: rgb(105, 105, 105);
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 300;
}

.doc4{
  color: rgb(90, 136, 247);
  font-family: Poppins, sans-serif;
  font-size: 16px;
}

.nextwavedoc{
  margin-top: 8px;
  margin-bottom: 2px;
}

.doc-row{
  margin-bottom: 12px;
}

html {
  scroll-behavior: smooth;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  color: #212529;
  margin: 0;
  padding: 0;
}

.ageInput {
  width: 90%;
  height: 36;
}

.stoneInput {
  width: 80%;
  height: 36;
}

.dropdown {
  width: 90%;
  height: 36;
}

<<<<<<< HEAD
=======
.active-nav-link {
  background-color: #3779CA;
  border-bottom: 2px solid white;
}

>>>>>>> main
@media (max-width: 824px) {
  .stoneInput {
    width: 80%;
  }
}

@media (max-width: 750px) {
  .stoneInput {
    width: 90%;
  }
}

@media (max-width: 625px) {
  .stoneInput {
    width: 80%;
  }
}
@media (max-width: 395px) {
  .stoneInput {
    width: 95%;
  }
}
